<template>
  <Transition>
    <div v-if="$store.state.sideNav.display" class="sn">
      <button class="sn__close" @click="$store.state.sideNav.display = false">
        <Svg size="3" icon="delete"></Svg>
      </button>
      <div class="sn__container">
        <img
          :src="strapi_url + '/uploads/medium_zappyschool_Copie_16215f81f2.png'"
        />
        <ul class="sn__nav">
          <li v-for="n of navPrim">
            <button
              :class="n.to.name === $route.name ? 'u-color-primary' : ''"
              @click="goToRoute(n.to)"
            >
              <Svg :icon="n.icon" size="1.8"></Svg>{{ n.label }}
            </button>
          </li>
        </ul>
        <div class="sn__formations">
          <h4>Nos formations</h4>
          <ul>
            <li v-for="f of formations">
              <button @click="goToRoute(f.to)"> {{ f.title }}</button>
            </li>
          </ul>
        </div>

        <ul class="sn__cta">
          <li>
            <button
              class="btn btn--secondary"
              @click="
                goToRoute({
                  name: 'Authentication',
                  params: { page: 'signup' },
                })
              "
            >
              S'inscrire
            </button>
          </li>
          <li>
            <button
              class="btn btn--primary"
              @click="
                goToRoute({ name: 'Authentication', params: { page: 'login' } })
              "
            >
              Réserver
            </button>
          </li>
        </ul>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import Svg from "@/components/utils/Svg";
import navPrim from "@/composables/data/navigation/navigation-primary.json";
import formations from "@/composables/data/formations.json";

import store from "@/store";
import { useRouter } from "vue-router";
const strapi_url = process.env.VUE_APP_STRAPI_DOMAIN;
const router = useRouter();
const goToRoute = (to) => {
  router.push(to);
  store.commit("closeSideNav");
};
</script>

<style lang="scss" scoped>
@import "@/style/main";
.sn {
  position: fixed;
  top: 0;
  right: 0;
  width: 40vw;

  @include respond(phone) {
    width: 100vw;
  }

  height: 100vh;
  background-color: $color-white;
  box-shadow: 0.3rem 0rem 1rem rgba($color-black, 0.6);

  z-index: 5000;

  &__close {
    @include buttonNoStyle;
    position: absolute;
    top: 2rem;
    left: 2rem;
    transition: all 0.2s;
    &:hover {
      transform: scale(1.1);
    }
  }

  &__container {
    background-color: $color-light;
    padding: 8rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    & img {
      width: 10rem;
      height: auto;
      margin-bottom: 5rem;
    }
  }

  &__formations {
    width: 100%;
    margin-top: 2rem;
    & h4 {
      font-size: 1.6rem;
      padding: 1rem 2rem;
    }

    & ul {
      display: flex;
      flex-wrap: wrap;
      padding: 1rem 2rem;
    }

    & li {
     display: flex;
      
      

      & button{
        @include buttonNoStyle;
        padding: 0.6rem 1.2rem;
        margin-right: 1rem;
      margin-bottom: 1rem;
      border-radius: 3px;
        background-color: $color-background-green;
        &:hover {
        background-color: lighten($color-background-green, 6);
      }
      }

     
    }
  }

  &__cta {
    margin-top: auto;
    display: flex;

    & li {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  &__nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    & li {
      width: 100%;
      //   background-color: $color-background-light-4;
      padding: 1rem 2rem;
      border-radius: 5px;

      & svg {
        margin-right: 2rem;
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
    & button {
      @include buttonNoStyle;
      font-size: 2rem;
      transition: all 0.2s;
      &:hover {
        color: $color-primary;
      }
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: all 0.4s ease;
}

.v-enter-from,
.v-leave-to {
  // top:0;
  // right:-50vw;
  transform: translateX(40vw);
  @include respond(phone) {
    transform: translateX(100vw);
  }
  // opacity: 0;
}
</style>
