<template>
  <label class="switch">
        <input type="checkbox" class="switch__input" v-model="bool">
        <span class="switch__slide switch__round"></span>
    </label>
</template>

<script>
import {computed} from 'vue';
export default {
    props: ['val'],
    setup(props, {emit}){
        const bool = computed({ 
            get: () => props.val, 
            set: (value) => emit('updateForm', {value: value}) 
        }) 
        
        return {bool}
    },
    emits:['updateForm']

}
</script>

<style lang="scss">
@import '@/style/main';

.switch {
    position: relative;
    display: inline-block;
    width: 5.4rem;
    height: 2.8rem;
    &__input {
        display: none;
    }
    &__slide {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;
        &::before {
            position: absolute;
            content: "";
            height: 2rem;
            width: 2rem;
            left: 0.4rem;
            bottom: 0.4rem;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }
    }
    &__round {
        border-radius: 3.4rem;
        &::before {
            border-radius: 50%;
        }
    }

    &__input:checked + &__slide::before {
        transform: translateX(2.6rem);
    }
    &__input:focus + &__slide {
        box-shadow: 0 0 1px $color-primary;
    }
    &__input:checked + &__slide {
        background-color: $color-primary;
    }
}

</style>