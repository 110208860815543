import axios from "axios";
import { getData, postData } from "@/composables/api/apiRequest";

import { createStore } from "vuex";
import cookies from "vue-cookies";

export default createStore({
  cart: [],
  state: {
    cart: {
      display: false,
      data: [],
      price: null,
      version:0
    },
    debts:null,
    banniere: "",
    loggedIn: false,
    
    notification: {
      color: "red",
      display: false,
      message: "",
    },
    popup: {
      display: false,
      data: {},
      formData: {},
      updateTable:false
    },
    sideNav:{
      display: false
    },
    updateComponent: false,
    user: false,
  },
  getters: {},
  mutations: {
    closeSideNav(state){
      state.sideNav.display = false;
    },
    hideNotification(state) {
      state.notification.display = false;
    },
    hidePopup(state, payload) {
      state.popup.display = false;
    },
    loggedIn(state, payload) {
      state.loggedIn = payload;
    },

    resetUpdateData(state, p) {
      state.updateData = {};
      state.popup.display = false;
      state.popup.isLoading = false;
      if(p && p.updateTable){
        state.popup.updateTable = p.updateTable
      }
    },
    setNotificationMessage(state, { message, color }) {
      state.notification.message = message;
      state.notification.color = color;
    },
    setUpdateComponent(state) {
      state.updateComponent = true;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    showCart(state) {
      state.cart.display = true;
    },
    showNotification(state) {
      state.notification.display = true;
    },
    showPopup(state, payload) {
      state.popup.display = true;
      state.popup.data = payload;
    },
  },
  actions: {
    async autoLogin(state) {
      if (!state.state.user) {
        if (cookies.get("jwt")) {
          //Set Bearer token for all further axios requests
          axios.defaults.headers["Authorization"] =
            "Bearer " + $cookies.get("jwt");

          const user = await getData("users/me");

          console.log('user', user)


          if (user) {
            state.commit("setUser", user);
            state.commit("loggedIn", true);


            await state.dispatch("getCart");
          } else {
            $cookies.set("jwt", "", 0);
          }
        }
      }
    },

    async updateUserProfile(state){
      axios.defaults.headers["Authorization"] =
            "Bearer " + $cookies.get("jwt");
      const user = await getData("users/me");
      if (user) {
        state.commit("setUser", user);
        await state.dispatch("getCart");
      } else {
        $cookies.set("jwt", "", 0);
      }
    },
    async getCart(state) {
      const res = await getData("cours/cart/" + state.state.user.user.user_id);
      state.state.cart.data = res.cours;
      state.state.cart.price = res.price;
      state.state.cart.version ++;
    },

    async displayCart(state){
      await state.dispatch("getCart");
      state.state.cart.display = true;
    },

    async getMyDebts(state){
      const res = await getData('/users/mydebts');
      console.log("debts",res);
      state.state.debts = res;
    },

    notification(state, { message, color }) {
      state.commit("setNotificationMessage", { message, color });
      state.commit("showNotification");
      setTimeout(() => {
        state.commit("hideNotification");
      }, 5000);
    },
  },
  modules: {},
});
