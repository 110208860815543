<template>
    <div id="nav-top" class="top">
      <div class="page__section top__section">
        <ul>
          <li v-for="i of top">
            <a :href="i.href">
              <Svg :icon="i.svg" size="1.6"></Svg>
              {{ i.label }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script setup>
  import Svg from "@/components/utils/Svg.vue";
  
  const top = [
    {
      label: "02/374.46.48",
      svg: "phone",
      href: "tel:003223744648",
    },
    {
      label: "info@zappyschool.com",
      svg: "email",
      href: "mailto:info@zappyschool.com",
    }
  ];
  </script>
  
  <style lang="scss">
  @import "@/style/main";

  
  .top {
    padding: 0 4rem;

    position: fixed;
      z-index: 100;
      width: 100%;
      top: 0;
      left: 0;
      height: $top-height;
      background-color: $color-secondary-dark;
      display: flex;
      align-items: center;

    &__section{
        width: 100%;
        flex-direction: row !important;
        justify-content: flex-end !important;
    }
      & ul {
        width: 100%;
        // padding: .5rem 0;
        display: flex;
        justify-content: flex-end;
      }
  
      & li {
        color: $color-white;
        &:not(:last-child) {
          margin-right: 2rem;
        }
  
        & a {
          @include buttonNoStyle;
          display: flex;
          align-items: center;
  
          & svg {
            margin-right: 1rem;
          }
        }
      }
  }
  </style>
  