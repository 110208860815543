<template>
  <div class="md">
    <div class="md__item md__left">
        <input-textarea
            :modelValue="modelValue"
            :data="dataExport"
            class="form__item"
            @updateForm="handleFormData"
            ></input-textarea>
    </div>
    <div class="md__item md__right">
        <div v-html="contentHtml ? contentHtml : 'La version HTML de votre texte apparaitra ici !'">

        </div>
    </div>
    
  </div>
</template>

<script setup>
import InputTextarea from "@/components/form/InputTextarea.vue";
import { ref } from "@vue/reactivity";

import showdown from "showdown";

const props = defineProps({
  data: Object,
  modelValue: String,
})


// On ne veut pas afficher le label ni le helper dans le textarea
const {label, helper, ...dataExport} = props.data

let converter = new showdown.Converter({
    emoji: true,
    noHeaderId: true,
  });




const emit = defineEmits(['updateValue'])

const contentHtml = ref(converter.makeHtml(props.modelValue));

const handleFormData = (val) => {
    contentHtml.value = converter.makeHtml(val.value);
    emit('updateValue', {field: props.data.name, value: val.value});
}

</script>

<style lang="scss">
@import "@/style/main";

.md{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;

    &__item{
        // background-color: red;
    }

    & p{
      margin-bottom: 1rem;
    }
}
</style>
