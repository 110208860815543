<template>
  <div>
  <label :for="id" class="form__checkbox--container">
    <input
      :id="id"
      type="checkbox"
      :name="name"
      class="form__checkbox"
      v-model="checkbox"
    />
    <div class="form__checkbox--mark"></div>
    <div class="form__checkbox--text" v-html="label"></div>
  </label>
</div>
</template>

<script setup>
import { ref, computed } from "vue";
const props = defineProps(["name", "label", "modelValue"]);
const emit = defineEmits(["updateForm"]);
const id = Math.floor(Math.random() * 1000);


const checkbox = computed({
  get: () => props.modelValue,
  set: (value) => emit("updateForm", { field: props.name, value: value }),
});
</script>

<style lang="scss">
@import "@/style/main";

.form {
  &__checkbox {
    display: none;

    &--container {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
    }

    &--mark {
      position: relative;
      height: 2.5rem;
      width: 2.5rem;
      background-color: $color-grey-10;
      margin-right: 1.3rem;
      border: 1px solid $color-grey-50;

      &::after {
        content: "";
        position: absolute;
        display: none;
        content: "\2713";
        font-size: 1.8rem;
        top: 48%;
        left: 50%;

        transform: translate(-50%, -50%);
      }
    }
    &--text {
      text-align: left;
      font-size: 1.3rem;
      flex: 1;
      & a {
        @include buttonNoStyle;
        @include hoverUnderline;
        color: $color-secondary;
      }
    }
    &--container:hover > &--mark {
      background-color: $color-grey-20;
      &::after {
        display: block;
        color: $color-grey-75;
      }
    }
    &:checked + &--mark {
      background-color: $color-primary;
      &::after {
        display: block;
        color: $color-white;
      }
    }
  }
}
</style>
