<template>
    <Popup />
    <!-- <Navigation/> -->
    <router-view/>
    <!-- <Help/> -->
    <Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer';
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';

import {getData} from "@/composables/api/apiRequest";

// import Navigation from '@/components/Navigation';
import Popup from '@/components/popup/Popup';
// import Help from '@/components/help/Help';


export default{
  components:{
    Footer,
    Popup
  },
  setup() {
    const store = useStore();
    onMounted(async () => {
      const ban= await getData('bannieres/current');
      store.state.banniere = ban.message;

      store.dispatch('autoLogin')


    })
  },
}
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Poppins:100,300,400,700,900&display=swap');

@import "@/style/main";
@import "@/style/components/button.scss";
@import "@/style/layout/page";

#app {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-text-dark;

  font-size: $default-font-size;

  box-sizing: border-box; /*Permet de dimensionner les box en incluant le padding */
  padding: 0rem; /*Mets une bordure au site*/

  & li{
    list-style: none;
  }

  ::selection {
    background-color: $color-orange-main;
    color: $color-white;
  }
}

</style>
