<template>
  <div v-if="data">
    <div class="popup__header" :class="`popup__header--secondary popup__header--split`">
       
      <h2 class="popup__header--title">
        Cours du {{ moment(data.date_cours).locale('fr').format('LL') }} de {{ data.heure_debut }} à {{ data.heure_fin }}
        </h2>
    </div>
    <div class="popup__main popup__main--small">
        <ul>
          <li>
            <b>Eleve:</b> {{ data.prenom }} {{ data.nom }}
          </li>
          <li>
            <b>Matières: </b> {{ data.matieres }}
          </li>
          <li>
            <b>Remarque: </b> {{ data.remarque }}
          </li>
        </ul>
      </div>

  </div>
</template>

<script setup>

import moment from "moment";

import { getData } from "@/composables/api/apiRequest";

import { onMounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";

const store = useStore();
const idCours = store.state.popup.data.idCours;
const data = ref({});

onMounted(async() => {
  data.value = await getData('/cours/eleve/'+idCours);
  console.log("deata", data.value);

})
 


</script>

<style lang="scss" scoped>
@import "@/style/main";
@import "@/style/components/popup";

.fEleve {
  &__info {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 1fr 1fr;

    &--item {
      padding: 1rem;
      // background-color: red;

      & h3 {
        margin-bottom: 1rem;
      }

      & li {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        & svg {
          margin-right: 1rem;
        }
      }
    }
  }
  &__contact {
  }
  &__stat {
  }
}
</style>
