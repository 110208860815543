<template>
  <div v-if="data.display" class="drop__container" v-click-outside="focusOut">
    <button class="btn__icon btn--invisible" @click="visible = !visible" >
      <Svg icon="details" size="1.8"></Svg>
    </button>
    <ul id="drop" class="drop" v-if="visible">
        <template v-for="(el, j) in data.list">
      <li  v-if="el.display">
        <button @click="selectAction(el)" >
            <Svg :icon="el.icon" size="1.6"></Svg>
          {{ el.label }}
        </button>
      </li></template>
    </ul>
  </div>
</template>

<script setup>
import Svg from "@/components/utils/Svg";
import { ref } from "vue";
const props = defineProps(["data"]);
const visible = ref(false);

const selectAction = (elem) => {
    elem.func(elem.args ? elem.args : false);
    visible.value = false;
}

const focusOut = (el) => {
    visible.value = false;
}
</script>

<style lang="scss">
@import "@/style/main";
.drop {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 3000;
  background-color: $color-white;
  border-radius: 5px;
  border: 1px solid #ccc;
//   width: 20rem;
white-space: nowrap;


  & li{
    
    &:not(:last-child){
        border-bottom: 1px solid #ccc;
    }
  }

  & button {
    @include buttonNoStyle;
    width: 100%;
    display: flex;
    align-items: center;
    padding: .6rem 1rem;
    &:hover{
        background-color: #eee;
    }
    & svg{
        margin-right: 1rem;
    }
  }

  &__container {
    position: relative;
  }
}
</style>
