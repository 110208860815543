<template>
  <div class="table__container" v-if="schema">
    <h2 class="table__title">
      {{ schema.title }}
    </h2>
    <p
      class="table__description"
      v-if="schema.description"
      v-html="schema.description"
    ></p>
    <div
      v-if="schema.pagination && schema.pageLines && schema.totalLines"
      class="table__pagination"
    >
      <ul>
        <li
          v-for="i in Math.ceil(schema.totalLines / schema.pageLines)"
          :class="schema.page === i ? `table__pagination--selected` : ''"
        >
          <button @click="paginate(i)">
            {{ i }}
          </button>
        </li>
      </ul>
    </div>
    <div v-if="tableData.length" class="table">
      <div
        class="table__line table__line--header"
        :class="schema.color ? `u-bck-${schema.color}` : ''"
      >
        <div
          v-for="col in schema.structure"
          :key="col.attr"
          class="table__col table__col--header"
          :class="`table__col--${col.width} ${
            col.sortable ? 'table__col--click' : ''
          }`"
          @click="col.sortable ? sortData(col.slug) : false"
        >
          {{ col.label }}
        </div>
      </div>
      <div
        v-for="(line, i) in tableData"
        :key="i"
        class="table__line"
        :class="line.color ? `table__line--${line.color}` : ''"
      >
        <div
          v-for="col in schema.structure"
          :key="col.slug"
          class="table__col"
          :class="[
            `table__col--${col.width}`,
            line[col.slug] && line[col.slug].color
              ? `table__col--${line[col.slug].color}`
              : '',
          ]"
        >
          <div
            v-if="col.type === 'text'"
            v-html="
              typeof line[col.slug] === 'object'
                ? line[col.slug]?.value
                : line[col.slug]
            "
          ></div>
          <div
            v-if="col.type === 'bool'"
            :class="line[col.slug] ? 'table__col--green' : 'table__col--red'"
          >
            {{ line[col.slug] }}
          </div>
          <div
            v-if="col.type === 'form'"
            class="table__form"
            style="width: 100%"
          >
            <form-generator
              :form="line[col.slug].form"
              @formData="(val) => handleFormData(val, line[col.slug].item)"
            ></form-generator>
          </div>
          <template v-if="col.type === 'textClick'">
            <button
              v-if="line[col.slug].label"
              class="btn__text"
              @click="line[col.slug].func(line[col.slug].funcArgs)"
              style="text-align: left"
            >
              {{ line[col.slug].label }}
            </button>
            <div
              v-else
              v-html="
                typeof line[col.slug] === 'object'
                  ? line[col.slug].value
                  : line[col.slug]
              "
            ></div>
          </template>
          <dropdown-button
            v-else-if="col.type === 'dropdown'"
            :data="line[col.slug]"
          ></dropdown-button>
          <div
            v-else-if="Array.isArray(line[col.slug]) && col.type !== 'array'"
            class="table__col--flex"
          >
            <template v-for="(el, j) in line[col.slug]">
              <component
                :is="el.tag"
                :to="el.to ? el.to : ''"
                :key="j"
                v-if="el.type === 'btn-icon' && el.display"
                class="btn btn__action table__action--btn"
                :class="[`btn--${el.color}`, 'btn__help']"
                @click.prevent="el.func(el.funcOptions)"
              >
                <template v-if="el.tag === 'button'">
                  <loading-button class="btn__center" :loading="el.loading">
                    <Svg :icon="el.icon" size="1.8"></Svg>
                  </loading-button>
                </template>
                <template v-else>
                  <Svg :icon="el.icon" size="1.8"></Svg>
                </template>
                <span v-if="el.help" class="btn__help--span">
                  {{ el.help }}
                </span>
              </component>
              <div v-else-if="!el.display && el.displayFalse">
                {{ el.displayFalse }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      {{ schema.empty ? schema.empty : "No Data" }}
    </div>
  </div>
</template>

<script setup>
import axios from "axios";

import { ref } from "@vue/reactivity";
import Svg from "@/components/utils/Svg";
import LoadingButton from "@/components/utils/LoadingButton";
import { useStore } from "vuex";
import { onMounted, watch } from "@vue/runtime-core";
import FormGenerator from "@/components/form/FormGenerator.vue";
import DropdownButton from "@/components/utils/DropdownButton.vue";

const props = defineProps(["data", "schema"]);
const emit = defineEmits(["updateTable", "updatePage"]);

const store = useStore();
const tableData = ref(props.data);

const sortData = (col) => {
  tableData.value.sort((a, b) =>
    a[col] > b[col] ? 1 : b[col] > a[col] ? -1 : 0
  );
};

const paginate = (pageNumber) => {
  emit("updatePage", { pageNumber });
};

const handleFormData = (val, lineData) => {
  emit("updateTable", { line: lineData, newVal: val.data });
};
</script>

<style lang="scss" ped>
@import "@/style/main";
@import "@/style/components/table";
</style>
