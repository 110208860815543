<template>
  <section id="faq" class="bgc--orange">
    <div class="page__section">
      <div class="index__title u-color-white">
        <h2>Questions fréquentes</h2>
        <h3> Voici les questions que posent les parents</h3>
      </div>
      <ul class="faq">
        <li v-for="f of data" class="faq__box">
          <home-faq-item :data="f"></home-faq-item>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup>
import HomeFaqItem from "@/components/home/HomeFaqItem";
const data = [
  {
    q: "Quelle est la procédure pour s’inscrire à la Zappy School ?",
    a: `C’est très simple : il suffit de créer un compte en complétant le formulaire d’inscription en ligne accessible <span id="link-faq-signin" class="index-faq__reponse--link">ici</span>.<br><br>
                    Dès que c’est fait, vous pourrez réserver des séances en groupe, des cours individuels ou des stages via notre site.<br><br>
                    Nous recommandons aussi de <span id="link-faq-contacter" class="index-faq__reponse--link">nous contacter</span> pour une entrevue préliminaire (gratuite et sans engagement) au cours de laquelle nous pourrons mieux comprendre la situation spécifique de vos enfants de façon à répondre au mieux à leurs besoins.
                    `,
  },
  {
    q: "Doit-on s’engager sur un certain nombre de séances lors de l’inscription ?",
    a: `   Non pas du tout : vous décidez de la fréquence, de la durée et de la périodicité des séances en groupe et des cours particuliers. Tout est laissé au libre choix des élèves et des parents.<br><br>
                    Si vous nous le demandez, nous exprimerons notre avis sur ce qu’il serait souhaitable de mettre en place mais rien ne vous sera imposé.<br><br>
                    La réservation de stages est également entièrement libre. Cependant, il est demandé dans ce cas un engagement pour l’ensemble des séances du stage.
`,
  },
  {
    q: "Doit-on payer des frais d’inscription au départ ?",
    a: `Non, l’inscription à la Zappy School est totalement gratuite.`,
  },
  {
    q: "Quelle est la procédure pour réserver des séances ?",
    a: `     Tout se passe via notre site internet, en cliquant sur le lien  <span id="link-faq-reserver" class="index-faq__reponse--link">Réserver un cours</span>.<br><br>
                Les séances en groupe et les cours particuliers doivent être réservées au minimum 2 jours à l’avance, pour nous permettre de nous organiser au niveau de l’encadrement. Par exemple, une séance demandée pour le jeudi doit être réservée au plus tard le mardi à minuit.<br><br>
                Les demandes tardives ne pourront pas être transmises via le site. Vous pourrez toujours nous contacter dans ce cas, mais des frais supplémentaires de 5€ par séance pourront être réclamés.
`,
  },
  {
    q: "Quelle est la procédure pour annuler une séance ?",
    a: `                    Une séance réservée ne peut pas être annulée via le site. En cas d’annulation, merci de <span id="link-faq-contacter" class="index-faq__reponse--link">nous contacter</span>. Toute séance annulée moins de 24h avant le rendez-vous prévu sera portée en compte.
`,
  },
  {
    q: "Comment procéder pour le paiement des séances ?",
    a: `                Les séances en groupe et les cours particuliers peuvent être réglés de deux manières :<br><br>
                <ul class="index-faq__reponse--ul">
                    <li class="index-faq__reponse--li">soit en cash au moment du cours ;</li>
                	<li class="index-faq__reponse--li">soit par virement bancaire à la fin de chaque mois lorsque le relevé récapitulatif mensuel vous est transmis par email.</li><br>
                </ul>
                Les stages doivent être réglés à l’avance par virement bancaire sur base de l’email de confirmation transmis lors de l’inscription.<br><br>
                A tout instant, les décomptes des cours ou stages passés et futurs sont consultables sur le site.

`,
  },
  {
    q: "Vos profs sont-ils suffisamment compétents ?",
    a: `  La majorité de nos profs sont des étudiants universitaires, le plus souvent de niveau Master (4e ou 5e année d’université) ou Post-graduat, ayant différentes spécialités. Nous recrutons nos profs avec le plus grand soin sur base de trois critères :<br><br>
                <ul class="index-faq__reponse--ul">
                    <li class="index-faq__reponse--li">La qualité de leur parcours scolaire et académique ;</li>
                	<li class="index-faq__reponse--li">Leur capacité à transmettre les notions de façon simple, claire et complète ;</li>
                	<li class="index-faq__reponse--li">Leur capacité à nouer avec les élèves une relation de qualité, faite de confiance, de respect, d’écoute, de non-jugement et d’empathie.</li><br>
                </ul>
                Nous sommes convaincus que la transmission efficace d’un savoir doit se fonder à la fois sur des qualités intellectuelles, communicationnelles et relationnelles qui suscitent l’envie et la motivation des élèves. Les jeunes profs qui se dévouent quotidiennement pour les élèves qui fréquentent notre centre cumulent ces trois qualités. La motivation qu’ils suscitent auprès des élèves produit des résultats qui surpassent de loin l’inconvénient que représente leur expérience pédagogique limitée.
`,
  },
];
</script>

<style lang="scss">
@import "@/style/main";
.faq {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  &__box {
    border: 2px solid $color-white;
    border-radius: 0.5rem;
    width: 100%;

    text-decoration: none;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__btn {
    text-decoration: none;
  }
}
</style>
