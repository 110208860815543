<template>
  <div class="popup">
    <button
      id="btn-popup-close"
      class="popup__close"
      @click="$store.commit('hidePopup')"
    >
      <Svg className="popup__close--svg" icon="delete"></Svg>
    </button>
    <div class="popup__content">
      <popup-update v-if="data.type === 'update'"></popup-update>
      <popup-confirm v-else-if="data.type === 'confirm'"></popup-confirm>
      <popup-preview-eleve
        v-else-if="data.type === 'preview-eleve'"
      ></popup-preview-eleve>
      <popup-preview-prof
        v-else-if="data.type === 'preview-prof'"
      ></popup-preview-prof>
      <popup-preview-cours
        v-else-if="data.type === 'preview-cours'"
      ></popup-preview-cours>
      <popup-files v-else-if="data.type==='files'"></popup-files>
      <popup-upload-files v-else-if="data.type==='uploadFiles'"></popup-upload-files>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import Svg from "@/components/utils/Svg";

import PopupUpdate from "@/components/popup/PopupUpdate";
import PopupConfirm from "@/components/popup/PopupConfirm";
import PopupPreviewCours from "@/components/popup/PopupPreviewCours.vue";
import PopupPreviewEleve from "@/components/popup/PopupPreviewEleve.vue";
import PopupPreviewProf from "@/components/popup/PopupPreviewProf.vue";
import PopupFiles from "@/components/popup/PopupFiles.vue";
import PopupUploadFiles from "@/components/popup/PopupUploadFiles.vue";

import { ref, watch } from "vue";

const store = useStore();
const data = ref(store.state.popup.data);
watch(store.state, (newVal, oldVal) => {
  data.value = newVal.popup.data;
});
</script>

<style lang="scss">
@import "@/style/main";

.popup {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(100, 100, 100, 0.05);

  z-index: 100000;
  opacity: 1;
  backface-visibility: hidden;

  transition: all 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;

  @supports (-webkit-backdrop-filter: blur(10px)) or
    (backdrop-filter: blur(10px)) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba($color-black, 0.3);
  }
  &__content {
    position: relative;
    max-width: $page-max-width;
    margin: 3rem;
    width: 100%;
    background-color: $color-white;
    opacity: 1;

    display: flex;
    flex-direction: column;

    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.5);
    @include respond(phone) {
      margin: 1.5rem;
    }
  }
  &__close {
    @include buttonNoStyle;
    position: absolute;
    top: 2rem;
    right: 2rem;

    color: $color-white;
    transition: all 0.2s;
    opacity: 0.5;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1000000;

    &:hover {
      opacity: 1;
      transform: scale(1.2);
    }

    &--svg {
      @include svg(2.2rem);
    }
  }
}
</style>
