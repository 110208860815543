<template>
  <template v-for="item in form">
    <template v-if="item.display !== undefined ? item.display : true">
      <input-textarea
        v-if="item.type === 'textarea'"
        :modelValue="item.value"
        :data="item"
        class="form__item"
        @updateForm="handleFormData"
      ></input-textarea>
      <input-checkbox
        v-else-if="item.type === 'checkbox'"
        :modelValue="item.value"
        :label="item.label"
        :name="item.name"
        class="form__item"
        @updateForm="handleFormData"
      ></input-checkbox>
      <input-choice
        v-else-if="item.type === 'choice'"
        :data="item"
        class="form__item"
        @updateForm="handleFormData"
      ></input-choice>
      <input-date-calendar
        v-else-if="item.type === 'date-calendar'"
        :data="item"
        class="form__item"
        @updateForm="handleFormData"
      ></input-date-calendar>
      <input-date
        v-else-if="item.type === 'input-date'"
        :modelValue="item.value"
        :inputdata="item"
        @updateForm="handleFormData"
        class="form__item"
      ></input-date>
      <input-select
        v-else-if="item.type === 'select'"
        :inputdata="item"
        :modelValue="item.value"
        @updateForm="handleFormData"
        class="form__item"
      ></input-select>
      <input-select-multiple
        v-else-if="item.type === 'select-multiple'"
        :data="item"
        :modelValue="item.value"
        @updateForm="handleFormData"
        class="form__item"
      >
      </input-select-multiple>
      <input-list-check
        v-else-if="item.type === 'input-list-check'"
        :inputdata="item"
        @updateForm="handleFormData"
        class="form__item"
      ></input-list-check>
      <input-image
        v-else-if="item.type === 'input-image'"
        :data="item"
        :modelValue="item.value"
        @updateForm="handleFormData"
        class="form__item"
      ></input-image>
      <input-file
        v-else-if="item.type === 'input-file'"
        :data="item"
        :modelValue="item.value"
        @updateForm="handleFormData"
        class="form__item"
      ></input-file>
      <input-boolean
        v-else-if="item.type === 'boolean'"
        :data="item"
        :modelValue="item.value"
        class="form__item"
        @updateForm="handleFormData"
      ></input-boolean>
      <input-schedule
        v-else-if="item.type === 'schedule'"
        :data="item"
        :modelValue="item.value"
        class="form__item"
        @updateForm="handleFormData"
      ></input-schedule>
      <input-markdown
        v-else-if="item.type === 'markdown'"
        :data="item"
        :modelValue="item.value"
        class="form__item"
        @updateForm="handleFormData"
      ></input-markdown>
      <input-search
        v-else-if="item.type === 'search'"
        :data="item"
        :modelValue="item.value"
        class="form__item"
        @updateForm="handleFormData"
      ></input-search>
      <input-rating
        v-else-if="item.type === 'rating'"
        :data="item"
        :modelValue="item.value"
        class="form__item"
        @updateForm="handleFormData"
      ></input-rating>

      <input-date-time-interval
        v-else-if="item.type === 'input-date-interval'"
        :data="item"
        :modelValue="item.value"
        class="form__item"
        @updateForm="handleFormData"
      ></input-date-time-interval>
      <input-check-text
        v-else-if="item.type === 'check-text'"
        :data="item"
        :modelValue="item.value"
        class="form__item"
        @updateForm="handleFormData"
      >
      </input-check-text>
      <input-text
        v-else
        :data="item"
        :modelValue="item.value"
        class="form__item"
        @updateForm="handleFormData"
      ></input-text>
    </template>
  </template>
</template>

<script setup>
import InputBoolean from "@/components/form/InputBoolean";
import InputDate from "@/components/form/InputDate";
import InputDateCalendar from "@/components/form/InputDateCalendar";
import InputFile from "@/components/form/InputFile";
import InputMarkdown from "@/components/form/InputMarkdown";
import InputSelect from "@/components/form/InputSelect";
import InputSelectMultiple from "@/components/form/InputSelectMultiple";
import InputChoice from "@/components/form/InputChoice";
import InputRating from "@/components/form/InputRating";
import InputSchedule from "@/components/form/InputSchedule";
import InputSearch from "@/components/form/InputSearch";
import InputText from "@/components/form/InputText";
import InputTextarea from "@/components/form/InputTextarea";
import InputCheckbox from "@/components/form/InputCheckbox";
import InputImage from "@/components/form/InputImage";
import { ref } from "@vue/reactivity";
import InputListCheck from "@/components/form/InputListCheck.vue";
import InputDateTimeInterval from "@/components/form/InputDateTimeInterval";
import { watch } from "vue";
import InputCheckText from "./InputCheckText.vue";

const props = defineProps(["form"]);
const emit = defineEmits(["formData"]);

const dataForm = ref({});
const filesArr = ref(null);

watch(
  () => props.form,
  (n, o) => {
    //If a field is removed from the form, we make sure the dataForm value is removed
    const values = n.map((el) => el.name);
    for (let key of Object.keys(dataForm.value)) {
      if (!values.includes(key)) {
        delete dataForm.value[key];
      }
    }
  },
  { deep: true }
);


const handleFormData = (val) => {
  dataForm.value[val.field] = val.value;

  if(val.files){
    filesArr.value = val.files;
  }

  const obj = { data: dataForm.value };

  if(filesArr.value){
    obj.files = filesArr.value;
  }

  emit("formData", obj);
};
</script>

<style lang="scss">
@import "@/style/main";
@import "@/style/components/form";
</style>
