<template>
  <div>
    <label v-if="data.label" class="form__label">
      {{ data.label }}
      <helper-input v-if="data.helper" :message="data.helper"></helper-input>
      <span v-if="data.input === 'password'" class="form__label--password">
       <Svg @click.prevent="toggleShowPassword" icon="watch" size="1.6"></Svg>
      </span>
    </label>
    <span class="form__error">{{ data.error }}</span>
    <input
      :type="inputType"
      :placeholder="data.placeholder"
      class="form__input"
      v-model="formValue"
      :disabled="data.disabled"
      :style="`color: ${data.color ? data.color : 'inherit'}`"
      @keypress="data.isNumber ? isNumber($event) : false"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import HelperInput from "./HelperInput";
import Svg from "@/components/utils/Svg.vue"

const props = defineProps(["data", "modelValue"]);
const emit = defineEmits(["updateForm"]);

const isPasswordShown = ref(false);
const inputType = ref(props.data.input || 'text');
const formValue = ref(props.modelValue);

const toggleShowPassword = () => {
  isPasswordShown.value = !isPasswordShown.value;
  if(isPasswordShown.value){
    inputType.value = 'text'
  }else{
    inputType.value = props.data.input || 'text'
  }
}

watch(formValue, (n, o) => {
  emit("updateForm", {
      field: `${props.data.name}${
        props.data.field ? "." + props.data.field : ""
      }`,
      value: n,
    });
})

/**
 * We check if the pressed key is a number of not.
 */
const isNumber = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    evt.preventDefault();
  } else {
    return true;
  }
};
</script>

<style lang="scss">
@import "@/style/main";
@import "@/style/components/form";
</style>
