<template>
   <svg :class="`icon-svg ${className ? className : ''}`" :style="size ? `height: ${size}rem; width: ${size}rem;` : ''">
        <use :href="`/img/illustration.svg#${icon}`"></use>
    </svg>
</template>

<script>
export default {
    props: [
        'className', 'icon', 'size'
    ],
}
</script>

<style lang="scss">
@import '@/style/main';

.icon-svg{
    fill: currentColor !important;
    transform: rotate(180deg) scaleX(-1);
}
</style>