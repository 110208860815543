<template>
  <div>
    <label v-if="data.label" class="form__label">
      {{ data.label }}
      <helper-input v-if="data.helper" :message="data.helper"></helper-input>
    </label>
    <span class="form__error">{{ data.error }}</span>
    <div class="rate">
      <ul class="rate__form">
        <li v-for="index in data.max_input">
          <input
            type="checkbox"
            class="rate--checkbox"
            :checked="index <= rating ? true : false"
          />
          <label
            class="rate--label"
            :class="index <= hoverRatingNote ? 'rate--label-hover' : ''"
            @mouseover="hoverRating(index)"
            @mouseleave="hoverRatingNote = 0"
            @click="selectRating(index)"
          >
            <Svg class="rate--svg" icon="star" size="2.5"></Svg>
          </label>
        </li>
      </ul>
      <div class="rate__form--comment">
        {{
          hoverRatingNote > 0
            ? data.comments[hoverRatingNote]
            : data.comments[rating]
        }}
      </div>
    </div>
  </div>
</template>

<script setup>
import Svg from "@/components/utils/Svg";
import { ref, computed } from "vue";
import HelperInput from "./HelperInput";
const props = defineProps(["data", "modelValue"]);
const emit = defineEmits(["updateForm"]);
const rating = ref(props.data.value ? props.data.value : 0);
const hoverRatingNote = ref(0);

const hoverRating = (val) => {
  hoverRatingNote.value = val;
};

const selectRating = (val) => {
  rating.value = val;
  emit("updateForm", {
    field: `${props.data.name}${
      props.data.field ? "." + props.data.field : ""
    }`,
    value: val,
  });
};
</script>

<style lang="scss">
@import "@/style/main";
@import "@/style/components/form";

.rate {
  display: flex;
  align-items: center;
  background-color: $color-grey-10;
  padding: 1rem;
  margin-top: 1rem;

  &__form {
    display: flex;
    align-items: center;
    margin-right: 3rem;

    &--comment{
      margin-left: auto;
    }
  }
  & li {
    display: flex;
    align-items: center;
    justify-content: center;

    & label {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:not(:last-child) {
      margin-right: 0.6rem;
    }
  }
  &--label {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 0.2rem;
    }

    &-hover {
      & svg {
        fill: $color-grey-30;
      }
    }
  }
  &--svg {
    color: $color-blue-main;

    stroke: currentColor;
    stroke-width: 0.5rem;
    @include svg(2.5rem);
    fill: none;

    &-selected {
      fill: currentColor;
    }
  }
  &--checkbox:checked + &--label > &--svg {
    fill: currentColor;
  }

  &--checkbox {
    display: none;
  }

  &--text {
    position: absolute;
    top: 100%;
    left: 50%;

    transform: translate(-50%, 0);

    text-transform: uppercase;

    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    width: 100%;
    text-align: center;
  }
}
</style>
