<template>
  <div @click="toggleVisibility" class="faq__question">
    <div class="faq__question--text">
      {{ data.q }}
    </div>
    <button :class="visible ? 'faq__question--up' : ''">
      <Svg icon="arrow-down" className="faq__question--arrow" size="2.5"></Svg>
    </button>
  </div>
  <div v-if="visible" class="faq__reponse" v-html="data.a"></div>
</template>

<script>
import Svg from "@/components/utils/Svg";
import { ref } from "@vue/reactivity";
export default {
  props: ["data"],
  components: {
    Svg,
  },
  setup() {
    const visible = ref(false);
    const toggleVisibility = () => {
      visible.value = !visible.value;
    };
    return {
      toggleVisibility,
      visible,
    };
  },
};
</script>

<style lang="scss">
@import "@/style/main";
.faq {
  &__question {
    cursor: pointer;
    padding: 1.4rem 2rem;

    color: white;
    display: flex;

    font-size: 1.8rem;
    text-transform: uppercase;

    letter-spacing: 0.1rem;

    align-items: center;


    &--text {
      text-decoration: none;

      margin-right: auto;
      font-weight: 600;
    }

    &--up {
      transform: rotate(180deg);
    }

    & button {
      @include buttonNoStyle;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--arrow {
      &:hover {
        transform: rotate(180deg) scaleX(-1) scale(1.1);
      }
    }
  }
  &__reponse {
    color: $color-white;
    padding: 2rem;
    padding-top: 0;

    // font-weight: 400;
    // text-transform: uppercase;

    font-size: 1.8rem;
    letter-spacing: 0.05rem;

    text-align: justify;

    &--link {
      display: inline;
      text-decoration: underline;
      color: inherit;

      &:active {
        color: darken($color-orange-main, 30);
      }
    }

    &--ul {
      transform: translateX(3%);
    }

    &--li {
      width: 95%;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style>
