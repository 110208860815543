<template>
  <div>
    <label v-if="data.label" class="form__label">
      <input-checkbox :modelValue="checkItem" @updateForm="handleCheckbox"></input-checkbox>
      {{ data.label }}
      <helper-input v-if="data.helper" :message="data.helper"></helper-input>
    </label>
    <input-text
      v-if="checkItem"
      :data="textForm"
      :modelValue="data.value"
      class="form__item"
      style="margin-top:1rem"
      @updateForm="handleTextField"
    ></input-text>
  </div>
</template>

<script setup>
import InputCheckbox from "@/components/form/InputCheckbox";
import InputText from "@/components/form/InputText";
import HelperInput from "./HelperInput";
import { ref } from "vue";

const props = defineProps(["data", "modelValue"]);
const emit = defineEmits(["updateForm"]);

const checkItem = ref(props.data.value ? true : false);
const currentTextValue = ref(null);

const textForm = {
    name:"textField"
}

//add fields to text field

for(let key of Object.keys(props.data.textField)){
    if(!textForm[key]){
        textForm[key] = props.data.textField[key];
    }
}

const handleCheckbox = (val) => {
    checkItem.value = val.value;
    emitSolution();
}

const handleTextField = (val) => {
    currentTextValue.value = val.value;
    textForm.value = val.value;
    emitSolution();
}

const emitSolution = () => {
    let val = currentTextValue.value;
    if(!checkItem.value){
        val = false;
    }else{
        if(!val){
            val = "";
        }
    }

    emit("updateForm", {
      field: `${props.data.name}${
        props.data.field ? "." + props.data.field : ""
      }`,
      value: val
    })
}


</script>
