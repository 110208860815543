<template>
<div v-if="data">
    <div class="popup__header" :class="`popup__header--secondary popup__header--split`">
        <h2 class="popup__header--title">
            {{data.eleve.prenom}} {{data.eleve.nom}} ({{data.eleve.nom_genre}})
        </h2>
        <h3 class="popup__header--title">
            {{data.eleve.annee_scolaire}} année
        </h3>
    </div>
    <div class="popup__main popup__main--small">
        <div class="fEleve__info">
            <div class="fEleve__info--item fEleve__stat">
                <h3>
                    Information de l'élève
                </h3>
                <ul>
                    <li v-for="c of eleve">
                        <Svg :icon="c.icon" size="2"></Svg>
                        <div v-html="c.label">
                        </div>
                    </li>
                </ul>
            </div>
            <div class="fEleve__info--item fEleve__contact">
                <h3>
                    Contact
                </h3>
                <ul>
                    <li v-for="c of contact">
                        <Svg :icon="c.icon" size="2"></Svg>
                        <div v-html="c.label">
                        </div>
                    </li>
                </ul>
            </div>
            <button v-for="email of data.emails" class="btn btn--primary" @click="connectClient(email.email)">Connect as {{email.email }}</button>
        </div>
        <div v-if="history" class="fEleve__cours">
            <table-generator :schema="tableSchemaFicheEleve" :data="history"></table-generator>
        </div>
    </div>
</div>

</template>

<script>
import TableGenerator from "@/components/table/TableGenerator";
import Svg from "@/components/utils/Svg";
import {connect} from "@/composables/utils/connect";
import tableSchemaFicheEleve from "@/composables/tables/fiche-eleve-history.json"

import moment from "moment";

import {getData} from "@/composables/api/apiRequest";

import { onMounted, ref } from '@vue/runtime-core';
import { useStore } from 'vuex'
export default {
    components: {
        Svg,
        TableGenerator
    },
    setup(){
        const store = useStore();
        const idEleve = store.state.popup.data.idEleve;
        const data = ref(null);
        const contact = ref(null);
        const eleve = ref(null);
        const history = ref(null);

        onMounted(async () => {
            data.value = await getData('eleves/'+idEleve);
            generateData();

        })

        const connectClient = async (email) => {
        // //WE get the user credentials
            await connect(email, false, 'eleve');
            store.commit('hidePopup');

        }
        const generateData = () => {
           
            contact.value = [
                {
                    icon:"email",
                    label:`<ul><li>${data.value.emails.map(el => el.email).join('</li><li>')}</li></ul>`
                },
                {
                    icon:"adress",
                    label:`${data.value.eleve.adresse_contact} <br> ${data.value.eleve.zip_contact} ${data.value.eleve.city_contact}`
                },
                {
                    icon:"phone",
                    label:`${data.value.eleve.prenom_contact} ${data.value.eleve.nom_contact}: ${data.value.eleve.portable_contact}`
                }
            ]

            eleve.value = [
                {
                    icon:"ecole",
                    label:data.value.eleve.ecole
                },
                {
                    icon:"lesson",
                    label:`<ul>
                        <li>Total de cours: ${data.value.stats.total}</li>
                        <li>Cours particuliers: ${data.value.stats.cours_particulier}</li>
                        <li>Ateliers: ${data.value.stats.ateliers}</li>
                        <li>Stages: ${data.value.stats.stages}</li>
                    </ul>`
                },
                {
                    icon:"phone",
                    label:data.value.eleve.telephone_portable
                },
                {
                    icon:"calendar",
                    label:`<ul>
                        <li>Dernier cours: </li>
                        <li>Date inscription: ${moment(data.value.elevedate_inscription).format('LL')} </li>
                    </ul>`
                }
            ]

            console.log(contact.value);

            history.value = [];
            for(let i of data.value.seances){
                const obj = {
                    type:i.type,
                    date: moment(i.date).format('ll'),
                    duree:i.duree ? moment.utc(i.duree*3600*1000).format('HH[h]mm') : '-',
                    matieres:i.matieres,
                    prof: i.prof ? i.prof : '-'
                }
                history.value.push(obj);
            }
        }



        return {
            connectClient,
            contact,
            data,
            eleve,
            history,
            tableSchemaFicheEleve
        }
    }
}
</script>


<style lang="scss" scoped>
@import '@/style/main';
@import '@/style/components/popup';

.fEleve{
    &__info{
        display: grid;
        column-gap: 1rem;
        grid-template-columns: 1fr 1fr;

        &--item{
            padding: 1rem;
            // background-color: red;

            & h3{
                margin-bottom: 1rem;
            }

             & li{
            display: flex;
            align-items: center;

            &:not(:last-child){
                margin-bottom: 1rem;
            }

            & svg{
                margin-right: 1rem;
            }
        }
        }
    }
    &__contact{
       
    }
    &__stat{
        
    }
}



</style>