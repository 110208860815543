<template>
<div v-if="data">
    <div class="popup__header" :class="`popup__header--secondary popup__header--split`">
        <h2 class="popup__header--title pprof__title" >
            {{data.prof.prenom}} {{data.prof.nom}}
            <span>{{moment().diff(data.prof.date_naissance, 'years',false)}} ans</span>
        </h2>
        <a :href="data.prof.cv" target="_blank" class=" btn btn--primary popup__header--title">
            CV
        </a>
    </div>
    <div class="popup__main popup__main--small">
        <div class="pprof__info">
             <div class="pprof__info--item pprof__contact">
                    <h3>
                        Information sur le prof
                    </h3>
                    <ul>
                        <li v-for="c of prof">
                            <Svg :icon="c.icon" size="2"></Svg>
                            <div v-html="c.label">
                            </div>
                        </li>
                    </ul>
                </div>
            <div class="pprof__info--item pprof__stat">
                    <h3>
                        Statistiques
                    </h3>
                    <ul>
                        <li v-for="c of stats">
                            <Svg :icon="c.icon" size="2"></Svg>
                            <div v-html="c.label">
                            </div>
                        </li>
                    </ul>
                </div>
        </div>
        <button class="btn btn--primary" @click="connectProf(data.prof.email)">Connect as {{data.prof.email}}</button>
        <div>
            <form-generator :form="formMenu" @formData="handleFormMenu"></form-generator>
        </div>
        <div class="pprof__table">
            <table-generator v-if="window === 'aptitudes' && aptitude" :schema="tableSchemaAptitude" :data="aptitude"></table-generator>
            <table-generator v-else-if="window === 'cours' && cours" :schema="tableSchemaCours" :data="cours"></table-generator>        
            <table-generator v-else-if="window === 'stages' && stages" :schema="tableSchemaStages" :data="stages"></table-generator>        
        </div>
    </div>
</div>


</template>

<script setup>
import FormGenerator from "@/components/form/FormGenerator";
import TableGenerator from "@/components/table/TableGenerator";

import moment from "moment";
import { ref } from "@vue/reactivity";
import {getData} from "@/composables/api/apiRequest";
import { useStore } from "vuex";
import { onMounted } from "@vue/runtime-core";
import {connect} from "@/composables/utils/connect";

import tableSchemaAptitude from "@/composables/tables/popup-prof-aptitude.json"
import tableSchemaCours from "@/composables/tables/popup-prof-cours.json"
import tableSchemaStages from "@/composables/tables/popup-prof-stage.json"

import Svg from "@/components/utils/Svg";

const store = useStore();
const idProf = store.state.popup.data.idProf;
const data = ref(null);
const stats = ref(null);
const prof = ref(null);
const window = ref('aptitudes')
const aptitude = ref(null);
const cours = ref(null);
const stages = ref(null);

onMounted(async () => {
    data.value = await getData('profs/preview/'+idProf);
    generateData();
})

const connectProf = async (email) => {
        // //WE get the user credentials
        await connect(email, false, "professeur");
        store.commit('hidePopup');
}

const formMenu = ref([
      {
        type:"choice",
        name:"menu",
        // label:"Menu",
        unique:true,
        options:[
          {
            label:"Aptitudes",
            value:"aptitudes",
            selected: window.value === 'aptitudes' ? true:false
          },
          {
            label:"Historique des cours",
            value:"cours",
            selected: window.value === 'cours' ? true:false
          },
           {
            label:"Historique des stages",
            value:"stages",
            selected: window.value === 'stages' ? true:false
          }
        ]
      }
    ]);


const handleFormMenu =(val) => {
    const newPage = val.data.menu.filter(el => el.selected === true)[0].value;
    window.value = newPage;
}

const generateData = () => {
    stats.value = [
                {
                    icon:"lesson",
                    label:`Cours chez Zappy School: ${data.value.cours.length + data.value.stages.length}`
                },
                {
                    icon:"calendar",
                    label:`Date d'inscription: ${moment(data.value.prof.date_inscription).locale('fr').format('dddd LL')}`
                },
                {
                    icon:"feedback",
                    label:`Note globale des feedbacks: ${'x'}`
                },
            ];
    prof.value =[
        {
            icon:"email",
            label:data.value.prof.email
        },
         {
            icon:"phone",
            label:data.value.prof.telephone_portable
        },
         {
            icon:"transaction",
            label:data.value.prof.compte_bancaire
        },
         {
            icon:"blocus",
            label:data.value.prof.nom_etude
        },
         {
            icon:"ecole",
            label:`Ecole supérieure: ${data.value.prof.ecole_supp}<br>Ecole secondaire: ${data.value.prof.ecole_secondaire}`
        },
         {
            icon:"adress",
            label:`${data.value.prof.adresse}<br> ${data.value.prof.code_postal} ${data.value.prof.ville}`
        }
    ]

    /// APTITUDE
    aptitude.value = [];

    for(let a of data.value.aptitudes){
        let degres = [];
        for(let i = 1; i< 4; i++){
            if(a['degre'+i] === 1){
                degres.push(i);
            }
        }

        const obj = {
            aptitude: a.matiere,
            check: degres.join(', ')
        };
        aptitude.value.push(obj);
    }

    //cours
    cours.value = [];
    for(let c of data.value.cours){
            const heures = moment
      .duration(
        moment(c.heure_fin, "HH:mm").diff(moment(c.heure_debut, "HH:mm"))
      )
      .asHours();

        let eleves = [];
        let noms, prenoms;

        if(c.nom_eleves){
            noms = c.nom_eleves.split(',');
            prenoms = c.prenom_eleves.split(',');
        }else{
            eleves.push('-')
        }

        for(let i = 0; i<noms.length; i++){
            eleves.push(`${prenoms[i]} ${noms[i]}`);
        }

        const obj = {
            eleves:eleves.join('<br>'),
            date:moment(c.date_cours).locale('fr').format('dddd LL'),
            duree:heures+" h"
        };
        cours.value.push(obj);
    }

     //cours
    stages.value = [];
    for(let c of data.value.stages){
        const duree = moment
      .duration(
        moment(c.heure_fin, "HH:mm").diff(moment(c.heure_debut, "HH:mm"))
      )
      .asHours();
        const obj = {
            stage:c.nom_stage,
            date:moment(c.date_cours).locale('fr').format('dddd LL'),
            duree:duree+" h"
        };
        stages.value.push(obj);
    }
}

</script>

<style lang='scss'>
.pprof{
    &__title{
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;

        & span {
            font-weight: 300;
            font-size: 1.4rem;
        }
    }

    &__table{
        margin-top: 2rem;
    }
     &__info{
        display: grid;
        column-gap: 1rem;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 2rem;

        &--item{
            // background-color: red;

            & h3{
                margin-bottom: 1rem;
            }

             & li{
            display: flex;
            align-items: center;

            &:not(:last-child){
                margin-bottom: 1rem;
            }

            & svg{
                margin-right: 1rem;
            }
        }
        }
    }
}

</style>