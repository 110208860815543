<template>
  <div>
    <label class="form__label">
      {{ inputdata.label }}
      <helper-input
        v-if="inputdata.helper"
        :message="inputdata.helper"
      ></helper-input>
    </label>
    <span class="form__error">{{ inputdata.error }}</span>
    <input
      :name="name"
      type="text"
      class="form__input"
      :placeholder="inputdata.placeholder"
      :value="valDate ? valDate.locale('fr').format('LL') : null"
      autocomplete="off"
    />
  </div>
</template>

<script setup>
import moment from "moment";
import daterangepicker from "daterangepicker";
import $ from "jquery";
import { computed, onMounted } from "vue";

import HelperInput from "@/components/form/HelperInput";

const props = defineProps(["inputdata", "modelValue"]);
const emit = defineEmits(["updateForm"]);

const name =
  props.inputdata.name +
  "-" +
  $('input[name^="' + props.inputdata.name + '"]').length;
const months = moment.months();
const valDate = props.modelValue ? moment(props.modelValue) : null;
const minYear = props.inputdata.minYear ? props.inputdata.minYear : 1915;
const maxYear = props.inputdata.maxYear
  ? props.inputdata.maxYear
  : moment().format("YYYY");
const showDropdowns = props.inputdata.hasOwnProperty("showDropdowns")
  ? props.inputdata.showDropdowns
  : true;

const minDate = props.inputdata.hasOwnProperty("minDate")
  ? moment(props.inputdata.minDate)
  : false;
const maxDate = props.inputdata.hasOwnProperty("maxDate")
  ? moment(props.inputdata.maxDate)
  : false;

onMounted(() => {
  listenerDate();
});

const listenerDate = () => {
  $(`input[name="${name}"]`).daterangepicker(
    {
      startDate: valDate ? valDate : false,
      singleDatePicker: true,
      timePicker: props.inputdata.timePicker
        ? props.inputdata.timePicker
        : false,
      showDropdowns: showDropdowns,
      autoUpdateInput: false,
      minYear: parseInt(minYear, 10),
      maxYear: parseInt(maxYear, 10),
      minDate,
      maxDate,
      locale: {
        format: "LL",
      },
    },

    function (selectedDate, end, label) {
      $('input[name="' + name + '"]').val(selectedDate.locale('fr').format("LL"));
      emit("updateForm", {
        field: props.inputdata.name,
        value: selectedDate.format("YYYY-MM-DD"),
      });
    }
  );
};
</script>

<style lang="scss">
@import "@/style/main";
@import "@/style/components/daterangepicker";

.cancelBtn {
  color: $color-primary !important;
  border: 1px solid currentColor !important;
}
</style>
