<template>
  <div>
    <label
      class="form__label"
      :class="{ 'form__label--center': data.labelcenter }"
    >
      {{ data.label }}
      <helper-input v-if="data.helper" :message="data.helper"></helper-input>
    </label>
    <div v-if="existingFiles">
      <ul v-if="existingFiles.length" class="infile">
        <li
          v-for="f of existingFiles"
          class="infile__item infile__item--existing"
        >
          <a :href="f.link" target="_blank" class="infile__link">
            {{ f.name }}
          </a>
          <button>
            <Svg size="1.6" icon="delete" @click="removeFile(f.name)"></Svg>
          </button>
        </li>
      </ul>
    </div>
    <div>
      <ul v-if="selectedFiles.length" class="infile">
        <li v-for="f of selectedFiles" class="infile__item">
          {{ f.name }}
          <button>
            <Svg size="1.6" icon="delete" @click="removeFile(f.name)"></Svg>
          </button>
        </li>
      </ul>
    </div>
    <label
      class="form__file--upload"
      :class="{ 'form__file--drag': dragOver }"
      @click="$refs.fileInput.click()"
      @dragover.prevent="dragOver = true"
      @dragleave="dragOver = false"
      @drop.prevent="onFileSelected"
      @dragenter.prevent
    >
      <Svg className="form__file--upload-svg" icon="document"></Svg>
      <div class="form__file--upload-text">
        Importer vos documents ou <span class="u-underline">browse</span>
      </div>
    </label>
    <input
      type="file"
      ref="fileInput"
      name="input-file"
      multiple
      :accept="data.accept ? data.accept : 'image/jpeg'"
      class="form__file--input"
      @change="onFileSelected"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import Svg from "@/components/utils/Svg";
import HelperInput from "./HelperInput";
const props = defineProps(["data", "modelValue"]);
const emit = defineEmits(["updateForm"]);

const dragOver = ref(false);

const existingFiles =
  typeof props.modelValue === "object"
    ? props.modelValue
    : props.modelValue
    ? [props.modelValue]
    : null;
    console.log('input-form', props.modelValue, existingFiles, typeof props.modelValue)
const selectedFiles = ref([]);

const removeFile = (fileName) => {
  selectedFiles.value = selectedFiles.value.filter(
    (el) => el.name !== fileName
  );
  emitData();
};

const onFileSelected = (e) => {
  if (e.type === "drop") {
    dragOver.value = false;
    if (e.dataTransfer.files) {
      for (let file of e.dataTransfer.files) {
        selectedFiles.value.push(file);
      }
    }
  }

  if (e.type === "change") {
    if (e.target.files.length) {
      for (let file of e.target.files) {
        selectedFiles.value.push(file);
      }
    }
  }

  emitData();
};

const emitData = () => {
  //EMIT EVENT
  const formData = new FormData();
  for (let file of selectedFiles.value) {
    formData.append("files", file);
  }

  emit("updateForm", { field: props.data.name, value: formData, files: selectedFiles.value });
};
</script>

<style lang="scss">
@import "@/style/main";
@import "@/style/components/form";

.infile {
  display: flex;
  margin: 1rem 0;
  &__item {
    padding: 0.4rem 0.9rem;
    background-color: $color-grey-15;
    margin: 0 1rem 1rem 0;
    display: flex;

    & button {
      @include buttonNoStyle;

      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 2rem;
      transition: all 0.2s;
      &:hover {
        color: $color-red;
      }
    }

    &--existing {
      background-color: $color-background-green;
    }
  }

  &__link {
    @include buttonNoStyle;
    @include hoverUnderline;
  }
}
</style>
