import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueCookies from "vue3-cookies";

import axios from "axios";

// AXIOS
axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL + "/v1/";
// axios.defaults.withCredentials = true;
axios.defaults.headers["Accept-Language"] = "en";
axios.defaults.headers["Authorization"] = "Bearer " + $cookies.get("jwt");



const clickOutside = {
    beforeMount: (el, binding) => {
      el.clickOutsideEvent = event => {
        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          binding.value();
        }
      };
      document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
      document.removeEventListener("click", el.clickOutsideEvent);
    },
  };

createApp(App).use(store).use(router).use(VueCookies).directive("click-outside", clickOutside).mount("#app");
