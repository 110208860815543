<template>
  <div v-if="data">
    
    <div
      class="popup__header"
      :class="`popup__header--secondary`" style="flex-direction:column"
    >
      <h2 class="popup__header--title">Fichier pour le cours de   {{ dataCours.nom_eleve ? dataCours.nom_eleve : `${dataCours.prenom} ${dataCours.nom}` }}</h2>
        <p>
            Du  {{ moment(dataCours.date_cours).locale('fr').format('dddd LL') }} de {{ dataCours.heure_debut ? dataCours.heure_debut : dataCours.h_debut }} à {{ dataCours.heure_fin ? dataCours.heure_fin : dataCours.h_fin }}
        </p>
    </div>
    <div class="popup__main popup__main--small">
        <ul class="fFiles">
            <li v-for="l of data">
                <p-file-item :data="l" @deletedFile="removeFileArray"></p-file-item>
                <!-- <div class="fFiles__content">
                    <img v-if="['png', 'jpg'].includes(l.file_name.split('.')[l.file_name.split('.').length-1].toLowerCase())" :src="s3Url+l.file_name">
                        <h4 v-else>
                        {{ l.file_name }}
                    </h4>
                </div>
                <div class="fFiles__form">
                    <form-generator :form="form" @formData="handleForm"></form-generator>
                    <button @click="saveComment(l)" class="btn btn--blue" style="margin-top:-2rem">Save</button>
                </div>

                <div class="fFiles__actions">
                    <a :href="s3CDNUrl+l.file_name" class="btn btn--primary">
                    Télécharger
                    </a>
                    <button @click="deleteFile(l)" class="btn btn--red">
                        Supprimer le fichier
                    </button>
                </div> -->

            </li>
        </ul>
    </div>
  </div>
</template>

<script setup>
import moment from "moment";
import { deleteData, getData } from "@/composables/api/apiRequest";
import { onMounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";

import pFileItem from "@/components/popup/pFileItem.vue";

const store = useStore();
const dataCours = store.state.popup.data.data;
const idCours = store.state.popup.data.idCours;

const data = ref(
    null
);
onMounted(async () => {
    await generateData();
});

const removeFileArray = (val) => {
    data.value = data.value.filter((el) => el.id !== val.id);
}

const generateData = async() => {
    data.value = await getData('cours/files/'+idCours);
}

</script>

<style lang="scss" scoped>
@import "@/style/main";
@import "@/style/components/popup";

.fFiles {

    & li{
        padding: 1rem 2rem;
        background-color: $color-background-light;
        border-radius: 5px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child){
            margin-bottom: 2rem;
        }

        & button{
            align-self: center;
        }
    }
}
</style>
