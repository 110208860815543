<template>
  <div class="interval">
    <input-date
      :modelValue="data.value"
      :inputdata="dateData"
      @updateForm="handleFormData"
      class="form__item interval__date"
    ></input-date>
    <input-hour
      :value="data.default && data.default.start ? data.default.start : false"
      :minHour="false"
      :data="startHourData"
      @updateForm="handleFormData"
      class="form__item interval__hour"
    ></input-hour>
    <input-hour
    :value="data.default && data.default.start ? data.default.end : false"
      :minHour="false"
      :data="endHourData"
      @updateForm="handleFormData"
      class="form__item interval__hour"
    ></input-hour>
  </div>
</template>

<script setup>
import InputDate from "@/components/form/InputDate";
import InputHour from "@/components/form/InputHour";

import moment from "moment";

import { ref } from "@vue/reactivity";

const props = defineProps(["data"]);
const emit = defineEmits(["updateForm"]);
const dataForm = ref({
    date:props.data.value,
    startHour:props.data.default && props.data.default.start ? props.data.default.start : false,
    endHour:props.data.default && props.data.default.end ? props.data.default.end : false
});
console.log(props, dataForm.value);
const dateData = {
  name: "date",
  label: "Date",
  placeholder: "Sélectionner une date",
  minYear:props.data.minYear,
  maxYear: props.data.maxYear
};

if(props.data.minDate){
  dateData.minDate = props.data.minDate
}
if(props.data.maxDate){
  dateData.maxDate = props.data.maxDate
}

const startHourData = {
  name: "startHour",
  label: "Heure de début",
  interval: {
    start: props.data.interval.start,
    end: moment(props.data.interval.end, "HH:mm")
      .add(-props.data.interval.minTime, "minutes")
      .format("HH:mm"),
    step: props.data.interval.step,
  },
};
const endHourData = ref({
  name: "endHour",
  label: "Heure de fin",
  interval: {
    start: moment(props.data.interval.start, "HH:mm")
      .add(props.data.interval.minTime, "minutes")
      .format("HH:mm"),
    end: props.data.interval.end,
    step: props.data.interval.step,
  },
});

const handleFormData = (val) => {
  dataForm.value[val.field] = val.value;
  if (val.field === "startHour") {
    updateEndInterval(val.value);
  }
  emit("updateForm", { field: props.data.name, value: dataForm.value });
};

const updateEndInterval = (startInterval) => {
  endHourData.value.interval.start = moment(startInterval, "HH:mm")
    .add(60, "minutes")
    .format("HH:mm");
};
</script>

<style lang="scss">
.interval {
  display: flex;
  flex-direction: row !important;
  &__date {
    flex: 1;
  }

  &__item {
  }

  &__hour {
    width: 31%;
    margin-left: 2rem;
  }
}
</style>
