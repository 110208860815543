<template>
  <div class="summary summary__zappy" style="background-image:url('/img/background/zappyschool.jpg')">
    <div class="summary__text">
        <div class="summary__textcontainer">
            <div class="summary__logo">
                <img src="img/logo/zappyschool.png" alt="Zappy School logo" class="summary__logo--img">
            </div>
            <div class="summary__accroche">
                <div class="summary__accroche--title-zappy">
                    Zappy School
                </div>
                <ul class="summary__accroche--list">
                    <li class="summary__accroche--elem">
                        Soutien scolaire pour élèves du secondaire
                    </li>
                    <li class="summary__accroche--elem">
                        Ouvert dimanche et jours fériés
                    </li>
                    <li class="summary__accroche--elem">
                        Pas de frais d'inscription
                    </li>
                    <li class="summary__accroche--elem">
                        Au Fort-Jaco à Uccle
                    </li>
                    <li class="summary__accroche--elem">
                        02/374.46.48
                    </li>
                </ul>
            
                <router-link :to="{name:'Authentication', params:{page:'login'}}" class="btn btn--primary summary__cta">
                    Réserver un cours <br> Se connecter 
                </router-link>
                 <router-link :to="{name:'Authentication', params:{page:'signup'}}" class="btn btn--primary-i summary__cta ">
                    S'inscrire gratuitement
                </router-link>
            </div>
        </div>   
    </div>
     <button class="summary__down">
        <Svg icon="arrow-down" size="3"></Svg>
    </button>

   
</div>
</template>

<script>
import Svg from "@/components/utils/Svg";
export default {
    components: {
        Svg
    }
}
</script>

<style lang="scss">
@import "@/style/main";

.summary {
	background-size: cover;
	background-position: center;
	border-radius: 0px;
	height: 90vh;

	position: relative;
    display: flex;
    justify-content: flex-end;

	&__zappy {
		background-image: linear-gradient(
				90deg,
				transparent 66%,
				rgba($color-white, 0.7) 0%,
				rgba($color-white, 0.7) 100%
			);

		@include respond(tab-port) {
			background-image: linear-gradient(
					90deg,
					transparent 50%,
					rgba($color-white, 0.7) 0%,
					rgba($color-white, 0.7) 100%
				)
		}

		@include respond(phone) {
			background-image: linear-gradient(
					90deg,
					transparent 00%,
					rgba($color-white, 0.7) 0%,
					rgba($color-white, 0.7) 100%
				)
		}
	}

    &__text{
        width: 33%;
        height: 100%;
        background-color: rgba($color-white,.7);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

	&__down {
        @include buttonNoStyle;
		position: absolute;
		top: 93%;
		left: 50%;

		transform: translate(-50%, -50%);

		background-color: rgba(255, 255, 255, 0.4);
		box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.4);

		padding: 1rem;
		border-radius: 50%;

		display: flex;
		justify-content: center;
		text-decoration: none;
		color: white;

		transition: all 0.2s;
		animation: arrowShake 1.8s infinite;

		// &:hover {
		// 	animation: arrowShake 0.2s;
		// }

		&:active {
			transform: translate(-50%, -50%) translateY(0.3rem);
		}
	}


	&__logo {
		margin: 0rem 5rem;
		margin-bottom: 3rem;

		&--img {
			height: 20rem;
			padding: 0rem;
		}
	}

	&__accroche {
		text-align: center;

		display: flex;
		flex-direction: column;

		&--title {
			font-weight: 900;
			font-size: 2.6rem;
			text-transform: uppercase;
			margin-bottom: 1rem;
		}

		&--title-zappy {
			font-weight: 900;
			font-size: 3.5rem;
			letter-spacing: 0.1rem;
			text-transform: uppercase;
			margin-bottom: 1rem;
		}

		&--list {
			margin-bottom: 3rem;
		}

		&--elem {
			list-style: none;
			font-size: 1.6rem;
			font-weight: 500;

			&:not(:last-child) {
				margin-bottom: 0.6rem;
			}
		}
	}

	&__cta {
		&:not(:last-child){
            margin-bottom: 1.5rem;
        }
	}
}


</style>