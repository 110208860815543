<template>
  <div class="navigation">
    <button v-for="n of nav" class="btn btn--primary navigation__item navigation__item--zappy">
        <Svg :icon="n.svg" className="navigation__logo" size="3"></Svg>
        {{n.label}}
    </button>
</div>
</template>

<script>
import nav from "@/composables/data/homeNav";

import Svg from "@/components/utils/Svg";
export default {
    components: {
        Svg
    },
    setup(){
        return {
            nav
        }
    }
}
</script>

<style lang='scss'>

@import "@/style/main";
.navigation{

	padding: 4rem 2rem;

	display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 1.5rem;
    column-gap: 1.5rem;
	max-width: 120rem;
	margin: 0 auto;
	justify-content:space-between;
	flex-wrap: wrap;


	@include respond(phone){
		flex-direction:column;
		align-items:center;
	}

	&__item{

		display: flex;
		align-items: center;
		justify-content:center;

		&--zappy{

		}
	}

	&__logo{
		margin-right: 1.5rem;

	}
}


</style>