import $ from "jquery";
import store from "../../store";

export const scrollTo = (target, offset = 0) => {
  const timeScroll = 500;
  const offsetNavTop = $("#nav-top").height(); //$("#navigation").height();
  const offsetNav = $("#nav-content").height(); //$("#navigation").height();

  $("html, body").animate(
    {
      scrollTop: $("#" + target).offset().top - offsetNav - offsetNavTop - offset,
    },
    timeScroll
  );

  //On affiche la navigation après un scroll
  setTimeout(() => {
    store.state.navDisplay = true;
  }, timeScroll + 100);
};
