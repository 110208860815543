<template>
  <template v-if="selectData">
    <input-select
      :inputdata="selectData"
      :modelValue="value"
      :key="version"
      @updateForm="handleFormData"
    ></input-select>
  </template>
</template>

<script setup>
import InputSelect from "@/components/form/InputSelect";
import { onMounted, ref, watch } from "@vue/runtime-core";

import moment from "moment";

const props = defineProps(["data", "value"]);
const emit = defineEmits(["updateForm"]);
const selectData = ref(null);
const version = ref(0);

const generateOptions = () => {
  const options = [
    {
      value: false,
      message: "Sélectioner une heure",
      disabled: true,
    },
  ];
  const startTime = moment(props.data.interval.start, "HH:mm");
  const endTime = moment(props.data.interval.end, "HH:mm");
  const intervals = moment.duration(endTime.diff(startTime)).asMinutes();

  // WE ADD THE START TIME
  options.push({
    value: startTime.format("HH:mm"),
    message: startTime.format("HH:mm"),
  });

  for (let i of Array(intervals / props.data.interval.step).keys()) {
    const time = startTime
      .add(props.data.interval.step, "minutes")
      .format("HH:mm");
    options.push({
      value: time,
      message: time,
    });
  }

  selectData.value = {
    label: props.data.label,
    name: props.data.name,
    value: false,
    options,
  };
  version.value++;
};

watch(
  () => props.data.interval.start,
  (n, o) => {
    generateOptions();
  }
);

onMounted(() => {
  generateOptions();
});

const handleFormData = (val) => {
  emit("updateForm", val);
};
</script>

<style></style>
