import $ from "jquery";

export const textareaAutogrow = (id) => {
  const that = document.getElementById(id);
  const emptyHeight = that.scrollHeight;
  const textHeight = textareaTextHeight(id);
  const bottomMargin = 50;

  //Init
  that.setAttribute("style", "height:" + emptyHeight + "px;overflow-y:hidden;");

  //Writing some text
  that.addEventListener("input", function() {
    let textHeight = textareaTextHeight(id);

    let height = textHeight + bottomMargin;

    if (height < emptyHeight) {
      height = emptyHeight;
    }

    that.setAttribute("style", "height: auto");
    that.setAttribute("style", "height:" + height + "px;");
  });
};

const textareaTextHeight = (id) => {
  // This function measures the height taken by the text inside a textarea without any present height.
  const elem = $("#" + id);
  const content = elem.val();
  const temp_div = $('<textarea id="temp"style="width:100%"></textarea>');
  temp_div.html(content);
  $("#" + id).after(temp_div);
  const height = $("#temp").prop("scrollHeight");
  temp_div.remove();

  return height;
};
