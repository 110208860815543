<template>
  <div id="nav-content" class="navi">
    <div class="navi__section page__section">
      <div class="navi--container">
        <a href="https://www.zappyschool.com" class="navi--logo">
          <img
            :src="
              strapi_url + '/uploads/medium_zappyschool_Copie_16215f81f2.png'
            "
            alt="ZappySchool"
            class="navi--logo-img"
          />
        </a>
        <nav class="navi__content u-hide-phone">
          <div class="navi__container">
            <ul class="navi__prim">
              <li v-for="k of navPrim" class="navi__prim--item">
                <router-link
                  :to="k.to"
                  :class="k.to.name === $route.name ? 'u-color-primary' : ''"
                >
                  {{ k.label }}
                </router-link>
              </li>
              <li class="navi__hover--container navi__prim--item">
                <button
                  class="navi__btn"
                  @click="displayFormations = !displayFormations"
                >
                  Préparations
                  <div
                    class="navi__btn--icon"
                    :class="{ 'navi__btn--180': displayFormations }"
                  >
                    <Svg icon="arrow-down" size="1.4"></Svg>
                  </div>
                </button>
                <div v-if="displayFormations" class="navi__hover">
                  <div class="navi__hover--content page__section">
                    <ul class="navi__hover--grid">
                      <li v-for="f of formations">
                        <img :src="strapi_url + f.img" alt="" />
                        <div>
                          <h4>
                            {{ f.title }}
                          </h4>
                          <p>
                            {{ f.descr }}
                          </p>
                          <router-link :to="f.to">
                            En savoir plus
                          </router-link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="navi__sec">
              <li v-for="n of nav[route.name]">
                <router-link v-if="n.to" :to="n.to">
                  {{ n.label }}
                </router-link>
                <button class="" @click="goTo(n.target)">
                  {{ n.label }}
                </button>
              </li>

              <li>
                <router-link
                  v-if="route.name != 'contact'"
                  :to="{ name: 'contact' }"
                >
                  Contact
                </router-link>
              </li>
            </ul>
          </div>
         
            
          
          <div class="navi__cta">
            <router-link
              :to="{ name: 'Authentication', params: { page: 'signup' } }"
              class="btn btn--secondary"
            >
              S'inscrire
            </router-link>
            <router-link
              :to="{ name: 'Authentication', params: { page: 'login' } }"
              class="btn btn--primary"
            >
              Réserver
            </router-link>
          </div>
          <div class="navi__burger">
            <button @click="$store.state.sideNav.display = true">
              <div class="navi__burger--line">
              &nbsp;
            </div>
            </button>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { scrollTo } from "@/composables/utils/scroll";
import { ref } from "vue";
import { useRoute } from "vue-router";
import Svg from "@/components/utils/Svg";
import navPrim from "@/composables/data/navigation/navigation-primary.json";

import formations from "@/composables/data/formations.json";

const strapi_url = process.env.VUE_APP_STRAPI_DOMAIN;
const route = useRoute();

const displayFormations = ref(false);
const goTo = (name) => {
  scrollTo(name);
};

const nav = {
  ateliers:[
  {
      label: "Les ateliers",
      target: "bref",
    },
    {
      label: "En pratique",
      target: "contact",
    },
    {
      label: "Comment démarrer ?",
      target: "details",
    },
    {
      label: "Notre école",
      target: "school",
    },
  ],
  cours:[
  {
      label: "Les cours",
      target: "bref",
    },
    {
      label: "En pratique",
      target: "contact",
    },
    {
      label: "Comment démarrer ?",
      target: "details",
    },
    {
      label: "Notre école",
      target: "school",
    },
  ],
  Home: [
    {
      label: "Notre Offre",
      target: "offre",
    },
    {
      label: "Notre Approche",
      target: "approche",
    },
    {
      label: "F.A.Q.",
      target: "faq",
    },
  ],
  stages:[
  {
      label: "Les stages",
      target: "bref",
    },
    {
      label: "En pratique",
      target: "contact",
    },
    {
      label: "Comment démarrer ?",
      target: "details",
    },
    {
      label: "Notre école",
      target: "school",
    },
  ],
};

</script>

<style lang="scss">
@import "@/style/main";

.navi {
  position: fixed;
  top: $top-height;
  left: 0;
  z-index: 100;
  width: 100%;

  background-color: $color-white;
  border-bottom: 1px solid currentColor;
  box-shadow: $shadow-light;

  &__section {
    height: $nav-height;
  }

  &__content {
    display: flex !important;
    align-items: center;
  }

  &__btn {
    display: flex;
    align-items: center;

    &--180 {
      transform: rotate(180deg) !important;
    }
    &--icon {
      margin-left: 1.5rem;
    }
  }

  &__burger{
    margin-left: 2rem;
    display: flex;
    align-items: center;
    align-self: stretch;

    & button{
      @include buttonNoStyle;
      height: 100%;


    }

    & button:hover > &--line::before{
      bottom: 1.3rem;
      height: 3px;
    }

    & button:hover > &--line::after{
      top: 1.3rem;
      height: 3px;
    }

    &--line{
      width: 3rem;
      height: 3px;
      background-color: $color-text-dark;
      border-radius: 2px;
      position: relative;

      &::before, &::after{
        width: 100%;
        height: 3px;
        content:"";
        position: absolute;
        background-color: $color-text-dark;
        border-radius: 2px;
        left: 0;
        transition: all .2s;
      }
      &::before{
        bottom: 1rem
      }

      &::after{
        top:1rem;
      }
    }
  }
  &__hover {
    background-color: $color-white;
    color: $color-text-dark;
    position: absolute;
    top: calc(100% + 0.1rem);
    left: 50%;
    padding: 2rem;
    border-radius: 5px;
    z-index: 2500;
    width: 100%;

    transform: translateX(-50%);
    display: flex;
    box-shadow: $shadow-light;

    &--grid{
      width: 100%;
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        column-gap: 3rem;
        row-gap: 3rem;
    }

    &--content {
      
      width: 100%;

      // & ul {
      //   width: 100%;
      //   display: grid;
      //   grid-template-columns: 1fr 1fr;
      //   column-gap: 3rem;
      // }

      & li {
        display: flex;
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
        background-color: $color-light;
        position: relative;

        & div{
          padding-left: 2rem;
          padding-right: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        & a{
          @include hoverUnderline;
          position: absolute;
          bottom: 1rem;
          right: 1rem;
          margin-top: 2rem;
          font-size: 1.4rem !important;
          
        }

        & h4 {
        }

        & p {
          font-weight: 400;
        }
      }
    }
  }

  &__cta {
    display: flex;
    // flex-direction: column;
    & a {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 2rem;
  }

  &__prim {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 0.6rem;

    @include respond(phone){
      display: none !important;
    }

    &--item{
      &:hover {
        color: $color-primary;
      }
    }
  }

  &__sec {
    @include respond(phone){
      display: none !important;
    }
    & button {
      @include hoverUnderline;
    }
  }

  &--container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & a {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  & ul {
    display: flex;
  }

  & li {
    &:not(:last-child) {
      margin-right: 2rem;
    }

    & a,
    button {
      @include buttonNoStyle;
    }
  }

  &--logo {
    width: 6rem;
    display: flex !important;
    align-items: center !important;

    &-img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  // }
}
</style>
