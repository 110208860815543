<template>
  <div class="banniere">
    <div class="banniere__text">
        {{$store.state.banniere}}
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
@import "@/style/main";
.banniere {
	background-color: $color-blue-main;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__text {
		padding: 2rem;
		font-size: 1.8rem;
		font-weight: 600;
		color: $color-text-light;

		text-transform: uppercase;
		letter-spacing: 0.1rem;
	}
}

</style>