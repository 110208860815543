<template>
  <div>
    <div
      class="popup__header popup__header--column"
      :class="`popup__header--${data.type}`"
    >
      <h2 class="popup__header--title">
        {{ data.title }}
      </h2>
      <h3 v-if="data.subtitle" v-html="data.subtitle"></h3>
    </div>
    <div class="popup__main popup__main--small">
      <form>
        <form-generator
          :form="data.form"
          @formData="handleFormData"
        ></form-generator>
      </form>
      <div class="popup__action">
        <button
          class="btn popup__action--item"
          :class="`btn--${data.color ? data.color : 'red'}-i`"
          @click="$store.state.popup.display = false"
        >
          Cancel
        </button>
        <button
          class="btn popup__action--item"
          :class="`btn--${data.color ? data.color : 'red'}`"
          @click="actionPopup"
        >
          <loading-button :loading="$store.state.popup.isLoading">
            {{ data.submit }}
          </loading-button>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import FormGenerator from "@/components/form/FormGenerator";
import LoadingButton from "@/components/utils/LoadingButton";

import axios from "axios";
import { ref } from "@vue/reactivity";

const store = useStore();
const data = store.state.popup.data;

const dataForm = ref(data.formData ? data.formData : {});

// WE RESET FORM DATA
store.state.popup.formData = null;

const handleFormData = (val) => {
    for(let key of Object.keys(val.data)){
        dataForm.value[key] = val.data[key];
    }
//   dataForm.value = val.data;
  store.state.popup.formData = dataForm.value;
};

const actionPopup = async () => {
  if (data.hasOwnProperty("actionFunc")) {
    store.state.popup.isLoading = true;
    return data.actionFunc(data.actionFuncArgs);
  }
  // ON adapate le formulaire en cas d'ajout d'images;

  if (Object.keys(dataForm.value).includes("image")) {
    const form = new FormData();
    Object.keys(dataForm.value).forEach((el) => {
      if (["image"].includes(el) && dataForm.value[el] instanceof FormData) {
        for (let pair of dataForm.value[el].entries()) {
          form.append(el, pair[1]);
        }
      } else {
        form.append(el, dataForm.value[el]);
      }
    });

    data.action.request.data = form;
  } else {
    data.action.request.data = dataForm.value;
  }

  try {
    store.state.popup.isLoading = true;
    const res = await axios(data.action.request);

    if (res.status == data.action.successStatus) {
      store.state.popup.isLoading = false;
      data.action.success.data = dataForm.value;

      store.state.popup.display = false;

      store.state.updateData = data.action.success; //You need to create a watcher in order to manage the success of the request
    }
  } catch (err) {
    console.log(err.response);
    store.state.popup.isLoading = false;
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/main";
@import "@/style/components/popup";
</style>
