<template>
  <div
    class="popup__header"
    :class="`popup__header--secondary`"
    style="flex-direction: column"
  >
    <h2 class="popup__header--title">
      Uploader des fichiers pour le cours du {{moment(data.date).format('LL')  }} de {{data.heure_debut}} à {{ data.heure_fin }}
    </h2>
  </div>
  <div class="popup__main popup__main--small">
    <form-generator :form="formFile" @formData="handleFiles"></form-generator>
    <button class="btn btn--primary" @click="uploadDocuments">
      <loading-button :loading="isLoading">
        Charger vos documents
      </loading-button>
    </button>
  </div>
</template>

<script setup>
import formFile from "@/composables/form/form-upload-file";
import FormGenerator from "@/components/form/FormGenerator.vue";
import LoadingButton from "@/components/utils/LoadingButton.vue";
import moment from "moment";
import { postData } from "@/composables/api/apiRequest";
import { useStore } from "vuex";
import { ref } from "vue";
const store = useStore();
const data = store.state.popup.data;
const dataForm = ref({});
const isLoading = ref(false);

console.log("store", data);

const handleFiles = (val) => {
  for (let key of Object.keys(val.data)) {
    dataForm.value[key] = val.data[key];
  }
};

const uploadDocuments = async () => {
  isLoading.value = true;
  console.log("upload Documents", data);

  const idCours = data.idCours;
  if(!idCours){
    store.dispatch("notification", {
      message: "Id Cours manquant",
      color: "red",
    });
    isLoading.value = false;
    return;
  }

  // FORMDATA IF DOCUMENTS
  const form = new FormData();
  Object.keys(dataForm.value).forEach((key) => {
    if (dataForm.value[key] instanceof FormData) {
      for (let pair of dataForm.value[key].entries()) {
        form.append(key, pair[1]);
      }
    } else {
      form.append(key, dataForm.value[key]);
    }
  });

  try {
    const res = await postData("cours/particulier/upload/"+idCours, form);
    if (res.result === "success") {
      isLoading.value = false;
      store.dispatch("notification", {
        message: "Vos documents ont été enregistrés",
        color: "green",
      });
      store.commit('resetUpdateData', {updateTable : true});

      isLoading.value = false;
    }
  } catch (err) {
    isLoading.value = false;
    store.dispatch("notification", {
      message: "Une erreur est survenue",
      color: "red",
    });
  }
};
</script>

<style lang="scss">
@import "@/style/main";
</style>
