<template>
  <div class="form__item">
    <label for="input-profile-gender" class="form__label">
      {{ data.label }}
      <helper-input
        v-if="data.helper"
        :message="data.helper"
      ></helper-input>
      <button
        v-if="data.action"
        class="form__label--btn"
        @click="handleAction"
      >
        {{ data.action.label }}
      </button>
    </label>
    <multiselect
      v-model="selected"
      :options="data.options"
      :multiple="true"
      :taggable="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="false"
      :hide-selected="true"
      :placeholder="data.placeholder"
      label="label"
      track-by="value"
      :preselect-first="false"
    >
    </multiselect>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import HelperInput from './HelperInput';
import Multiselect from "vue-multiselect";
const props = defineProps(['data', 'modelValue']);
const emit = defineEmits(['updateForm']);
const selected = ref(props.data.value ? props.data.value : null);

watch(selected, (n, o) => {
    emit('updateForm', {field: `${props.data.name}${props.data.field ? '.'+props.data.field: ''}`, value: selected.value.map(el => el.value)});
})

</script>

<style src="@/assets/vue-multiselect.min.css"></style>
<style lang="scss">
.multiselect__content-wrapper{
    position: relative !important;
}
</style>