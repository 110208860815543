<template>
  <div class="fFile__content">
    <img
      v-if="
        ['png', 'jpg'].includes(
            file.file_name
            .split('.')
            [file.file_name.split('.').length - 1].toLowerCase()
        )
      "
      :src="s3Url + file.file_name"
    />
    <h4 v-else>
      {{ file.file_name }}
    </h4>
  </div>
  <div class="fFile__form">
    <form-generator :form="form" @formData="handleForm"></form-generator>
    <button
        v-if="dataForm.comment"
      @click="saveComment(l)"
      class="btn btn--blue"
      style="margin-top: -2rem"
    >
      Sauvegarder
    </button>
  </div>

  <div class="fFile__actions">
    <a :href="s3CDNUrl + file.file_name" class="btn btn--primary"> Télécharger </a>
    <button @click="deleteFile(file)" class="btn btn--red">
      Supprimer le fichier
    </button>
  </div>
</template>

<script setup>
import FormGenerator from "@/components/form/FormGenerator.vue";
import { deleteData, patchData } from "@/composables/api/apiRequest";
import { ref } from "vue";
import { useStore } from "vuex";
const store = useStore();

const props = defineProps(['data']);
const emit = defineEmits(["deletedFile"]);


const file = props.data;

const apiUrl = process.env.VUE_APP_API_BASEURL;
const s3Url = process.env.VUE_APP_S3_PUBLIC;
const s3CDNUrl = process.env.VUE_APP_S3_CDN;

const dataForm = ref({});

const form = [
{
        "label": "Commentaire",
        "placeholder": "Commentaire à propos du document",
        "name": "comment",
        "type": "textarea",
        value: file.comment ? file.comment : ''
      }
];

const saveComment = async () => {
    const res = await patchData('cours/files/'+file.id, dataForm.value);
    dataForm.value = {};
    store.dispatch('notification', {
        message:'Commentaire sauvegardé',
        color:'green'
    })
}

const handleForm = (val) => {
    dataForm.value = val.data;
};


const deleteFile = async (fileData) => {
  const delFile = await deleteData({
    url: "cours/files/" + fileData.id,
  });

  emit("deletedFile", {
      id:file.id,
    });
};
</script>

<style lang="scss" scoped>
@import "@/style/main";
@import "@/style/components/popup";

.fFile {

    &__form{
        flex:1;
        margin: 0 2rem;
    }
    &__content{
        display: flex;
        & img{
            height: 10rem;
            width: auto;
            margin-right: 2rem;
        }
    }

    &__actions{
        display: flex;

        & button{
            margin-left: 2rem;
        }

    }


}
</style>
