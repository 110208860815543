import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "@/store/index";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "Soutien Scolaire",
    },
    component: HomeView,
  },
  {
    path: "/acces",
    name: "Access",
    meta: {
      title: "Accès",
    },
    component: () =>
      import(/* webpackChunkName: "access" */ "../views/AccessView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    meta: {
      title: "Contact",
    },
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
  },
  {
    path: "/feedback/:type/:id",
    name: "Feedback",
    meta: {
      title: "Feedback",
    },
    component: () =>
      import(/* webpackChunkName: "feedback" */ "../views/FeedbackView.vue"),
  },
  {
    path: "/verification/:id/:token",
    name: "Verification",
    meta: {
      title: "Vérification de votre email",
    },
    component: () =>
      import(/* webpackChunkName: "verif" */ "../views/VerificationView.vue"),
  },
  {
    path: "/password-forgot",
    name: "PasswordForgotten",
    meta: {
      title: "Mot de passe oublié",
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot" */ "../views/PasswordForgottenView.vue"
      ),
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    meta: {
      title: "Créer un nouveau mot de passe",
    },
    component: () =>
      import(/* webpackChunkName: "reset" */ "../views/PasswordResetView.vue"),
  },
  {
    path: "/authentification/:page",
    name: "Authentication",
    meta: {
      title: "Login",
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/AuthenticationView.vue"),
  },
  {
    path: "/payment/:page?",
    name: "Payment",
    meta: {
      title: "Paiement",
    },
    component: () =>
      import(/* webpackChunkName: "pay" */ "../views/PaymentView.vue"),
  },
  {
    path: "/dashboard/:type/:page",
    name: "Dashboard",
    meta: {
      title: "Mon Espace",
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/DashboardView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/stages/:cycle?/:slug?",
    name: "stage",
    meta: {
      title: "Stages",
    },
    component: () =>
      import(/* webpackChunkName: "stage" */ "../views/StageView.vue"),
  },
  {
    path: "/form/:page",
    name: "form",
    meta: {
      title: "Formulaire",
    },
    component: () =>
      import(/* webpackChunkName: "form" */ "../views/FormView.vue"),
  },
  {
    path: "/conditions-generales",
    name: "terms",
    meta: {
      title: "Conditions générales",
    },
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/TermsView.vue"),
  },
  {
    path: "/vie-privee",
    name: "privacy",
    meta:{
      title: "Politique de confidentialité",
    },
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/PrivacyView.vue"),
  },
  {
    path: "/cours-particulier",
    name: "cours",
    meta: {
      title: "Cours particuliers",
    },
    component: () =>
      // import(/* webpackChunkName: "cours" */ "../views/CoursView.vue"),
      import(/* webpackChunkName: "stage" */ "../views/StageView.vue"),
  },
  {
    path: "/atelier",
    name: "ateliers",
    meta: {
      title: "Ateliers",
    },
    component: () =>
    import(/* webpackChunkName: "stage" */ "../views/StageView.vue"),
  },
  {
    path: "/stage",
    name: "stages",
    meta: {
      title: "Stages",
    },
    component: () =>
      import(/* webpackChunkName: "stage" */ "../views/StageView.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/NotFoundView.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  //PROTECTED PAGES
  const protectedPages = [
    {
      name: "Dashboard",
    },
    {
      name: "Payment",
    },
  ];

  if (protectedPages.map((el) => el.name).includes(to.name)) {
    await store.dispatch("autoLogin");


    if (!store.state.loggedIn) {
      next({ name: "Authentication", params: { page: "login" } });
    }
  }

  // // WE REDIRECT USER TO DASH IF HE IS CONNECTED
  // if (to.name === "Authentication") {
  //   await store.dispatch("autoLogin");
  //   if (store.state.loggedIn) {
  //     next({ name: "Dashboard" });
  //   }
  // }

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    // const lang = to.query.locale ?
    // console.log(to.query.locale);
    document.title = `${process.env.VUE_APP_TITLE} | ${nearestWithTitle.meta.title}`;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(
          key,
          key === "content" ? tagDef[key][store.state.language] : tagDef[key]
        );
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  // ROUTE PROTECTION

  next();
});

export default router;
