<template>
    <div>   
        <label v-if="data.label" class="form__label">
            {{data.label}}
            <helper-input v-if="data.helper" :message="data.helper"></helper-input>
        </label>
        <span class="form__error">{{data.error}}</span>
        <markdown-editor :data="data" :modelValue="modelValue" @updateValue="handleMarkdownText"></markdown-editor>
    </div>

</template>

<script setup>
import HelperInput from '@/components/form/HelperInput';
import MarkdownEditor from "@/components/form/MarkdownEditor";

const props = defineProps(['data', 'modelValue']);
const emit = defineEmits(['updateForm']);


const handleMarkdownText = (val) => {
  emit('updateForm', val);
}


</script>

<style lang="scss">
@import "@/style/main";


</style>