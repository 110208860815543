<template>
  <div class="calendar">
    <div class="calendar__header">
      <h5 v-if="data.label">
        {{ data.label }}
      </h5>
      <div class="calendar__header--nav">
        <button id="date-prev">
          <Svg icon="arrow-left " size="2"></Svg>
        </button>
        <button id="date-next">
          <Svg icon="arrow-right" size="2"></Svg>
        </button>
      </div>
    </div>
    <div id="carousel-dates" class="calendar__dates">
      <date-calendar-button
        v-for="i in data.limit"
        :selectedDate="selectedDate.format('YYYY-MM-DD')"
        :date="
          data.startDate
            ? data.startDate
                .clone()
                .add(i - 1, 'days')
                .format('YYYY-MM-DD')
            : moment()
                .add(i - 1, 'days')
                .format('YYYY-MM-DD')
        "
        @click="
          changeSelectedDate(
            data.startDate
              ? data.startDate.clone().add(i - 1, 'days')
              : moment().add(i - 1, 'days')
          )
        "
      >
      </date-calendar-button>
    </div>
  </div>
</template>

<script setup>
import DateCalendarButton from "@/components/form/DateCalendarButton";
import Svg from "@/components/utils/Svg";

import moment from "moment";
import slick from "slick-carousel";
import $ from "jquery";

import { onMounted, ref } from "@vue/runtime-core";
import { useRoute } from "vue-router";
const props = defineProps(["data"]);
const emit = defineEmits(["updateForm"]);

const route = useRoute();
const selectedDate = ref(props.data.selectedDate);
// const selectedDate = ref(moment("2023-05-15"));

onMounted(() => {
  const datesToShow = $("#carousel-dates").width() / 100 + 1;

  const datesToShowInt = datesToShow.toFixed() * 1;
  $("#carousel-dates").slick({
    infinite: true,
    slidesToShow: datesToShowInt * 1,
    slidesToScroll: datesToShowInt - 1, //datesToShow.toFixed()
    prevArrow: $("#date-prev"),
    nextArrow: $("#date-next"),
    
  });

  let diffSelection = selectedDate.value.diff(props.data.startDate,'days')

  $("#carousel-dates").slick('slickGoTo', diffSelection)

});

const changeSelectedDate = (date) => {
  selectedDate.value = date;
  emit("updateForm", {
    field: props.data.name,
    value: date.format("YYYY-MM-DD"),
  });
};
</script>

<style lang="scss">
@import "@/style/main";
@import "@/style/components/slick";
.calendar {
  &__header {
    display: flex;
    align-items: center;

    & h5 {
      font-size: 1.6rem;
    }

    &--nav {
      margin-left: auto;

      & button {
        @include buttonNoStyle;
        transition: all 0.2s;
        &:not(:last-child) {
          margin-right: 1rem;
        }

        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  &__dates {
    display: flex;
    // flex-wrap: wrap;
    // margin-top: 3rem;
    // width: 100%;
    & button {
      @include buttonNoStyle;
      list-style: none;
      cursor: pointer;
    }

    // &--nav{
    //     margin-left: auto;

    //     & button{

    //         @include buttonNoStyle;
    //         transition: all .2s;
    //         &:not(:last-child){
    //             margin-right: 1rem;
    //         }

    //         &:hover{
    //             color:$color-primary;
    //         }
    //     }
    // }
  }
}
</style>
