import router from "@/router";
import store from "@/store";
import { postData } from "@/composables/api/apiRequest";

export const connect = async (email, prenom = false, typeProfil) => {
    // //WE get the user credentials
    const res = await postData('/auth/connect/user/'+email);
    if(res.status === 'success'){
      $cookies.set("jwt-admin", $cookies.get('jwt'), "1M");
      $cookies.set("jwt", res.token, "3M");
      router.push({name:'Dashboard', params:{type:typeProfil, page:'accueil'}});
      store.dispatch('updateUserProfile');
      store.dispatch('notification', {
        message:`Vous êtes maintenant connecté comme ${prenom ? prenom : email}`,
        color:'green'
      })
    }
  }