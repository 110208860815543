<template>
  <div>
    <label
      class="form__label"
      :class="{ 'form__label--center': data.labelcenter }"
    >
      {{ data.label }}
      <helper-input v-if="data.helper" :message="data.helper"></helper-input>
    </label>

    <label
      v-if="!previewImage"
      class="form__file--upload"
      :class="{ 'form__file--drag': dragOver }"
      @click="$refs.imageInput.click()"
      @dragover.prevent="dragOver = true"
      @dragleave="dragOver = false"
      @drop.prevent="onFileSelected"
      @dragenter.prevent
    >
      <Svg className="form__file--upload-svg" icon="camera"></Svg>
      <div class="form__file--upload-text">
        Drag your image here or <span class="u-underline">browse</span>
      </div>
    </label>
    <div v-else class="form__file--preview">
      <button
        class="form__file--preview-btn"
        @click.prevent="$refs.imageInput.click()"
      >
        <Svg className="form__file--preview-svg" icon="camera"></Svg>
        Upload another image
      </button>
      <button class="form__file--remove" @click="previewImage = null">
        <Svg className="form__file--remove-svg" icon="delete"></Svg>
      </button>
      <img :src="previewImage" alt="" class="form__file--img" />
    </div>
    <input
      type="file"
      ref="imageInput"
      name="pictureNewTrail"
      :accept="data.accept ? data.accept : 'image/png, image/gif, image/jpeg'"
      class="form__file--input"
      @change="onFileSelected"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import Svg from "@/components/utils/Svg";
import HelperInput from "./HelperInput";

const props = defineProps(["data", "modelValue"]);
const emit = defineEmits(["updateForm"]);

const dragOver = ref(false);
const selectedImage = ref([]);

const previewImage = ref(props.modelValue);


watch(props, (newVal, oldVal) => {
  previewImage.value = newVal.modelValue ? newVal.modelValue : "";
});

if (props.data.image) {
    
  previewImage.value = props.data.image;
}

const onFileSelected = (e) => {
  if (e.type === "drop") {
    dragOver.value = false;
    selectedImage.value = e.dataTransfer.files[0];
  }

  if (e.type === "change") {
    if (e.target.files.length) {
      selectedImage.value = e.target.files[0];
    }
  }

  //Create form data
  const reader = new FileReader();
  reader.onload = function (e) {
    previewImage.value = e.target.result;
  };
  reader.readAsDataURL(selectedImage.value); // convert to base64 string

  //EMIT EVENT
  const formData = new FormData();
  formData.append("image", selectedImage.value);
  emit("updateForm", { field: props.data.name, value: formData });
};
</script>

<style lang="scss">
@import "@/style/main";
@import "@/style/components/form";
</style>
